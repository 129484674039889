import * as React from "react"
import "../scss/PortfolioPage.scss"
import Seo from "../components/seo/seo"

import PortfolioList from "../shared/portfolio-list/Portfolio-list"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const Portfolio = props => {
  const portfolioList = props.pageContext.resultPortfolio
  const staticContent = props.pageContext.staticContent

  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage

  return (
    <div>
      <div className="container portfolio-page">
        <Header
          menu={menu}
          siteTitle={staticContent["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticContent["<meta> keywords"]}
          descriptionContent={staticContent["<meta> description"]}
          title={staticContent["<title>"]}
        />
        <PortfolioList
          staticContent={props.pageContext.staticContent}
          portfolio={portfolioList}
          itemsInRow={2}
          video={true}
        />
      </div>

      <Footer recaptchaSettings={props.pageContext.recaptchaSettings} staticContactsForm={props.pageContext.staticContactsForm} staticContactsPage={props.pageContext.staticContactsPage} />

    </div>
  )
}

export default Portfolio
