import React, {useState } from "react"
import "./Portfolio-list.scss"

import LearnMoreBtn from "../learn-more-btn/LearnMoreBtn"
import { Modal } from "react-bootstrap"
import { getImageUrl } from "../../services/Utils"

const PortfolioList = ({portfolio, itemsInRow, learnMoreBtnText, video, staticContent }) => {
  const [show, setShow] = useState(false)
  const [videoLink, setVideoLink] = useState()
  const handleClose = () => setShow(false)
  const handleShow = link => {
    setVideoLink(link)
    setShow(true)
  }

  const configureItemsInTheRowAmount = itemsInRow => {
    if (itemsInRow === 2) {
      return "col-lg-6"
    } else if (itemsInRow === 4) {
      return "col-md-6 col-lg-3"
    }
  }

  return (
    <div className="projects-list row flex-wrap mb-6">
      <div className="text-title" dangerouslySetInnerHTML={{ __html: staticContent['Page header'] }}/>
      {portfolio && portfolio.length ? portfolio.map(item => {
        return (
          <div
            className={`projects-list__item ${configureItemsInTheRowAmount(
              itemsInRow
            )} mt-5 curs-P`}
          >
            {video ? (
              <div
                onClick={() => {
                  handleShow(item?.videoLink)
                }}
              >
                <img
                  src={getImageUrl(item?.imageUrl)}
                  alt="No image"
                  className="rounded-img"
                />
                <div className="title-description-wrapper">
                  <div className="title mb-3">{item?.title}</div>
                  <div className="description mb-3" dangerouslySetInnerHTML={{ __html: item?.description }}/>
                </div>
                <div>
                  <LearnMoreBtn label={learnMoreBtnText} />
                </div>
              </div>
            ) : (
              <>
                <img
                  src={getImageUrl(item?.imageUrl)}
                  alt="No image"
                  className="rounded-img"
                />
                <div className="title-description-wrapper">
                  <div className="title mb-3">{item?.title}</div>
                  <div className="description mb-3" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                </div>
                <div>
                  <LearnMoreBtn label={learnMoreBtnText} />
                </div>
              </>
            )}
          </div>
        )
      }) : null}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="video-modal"
      >
        <Modal.Body>
          <iframe
          type="text/html"
          key={1}
          title="YouTube video player"
          frameBorder="0"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          src={videoLink}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PortfolioList
